<template>
  <div class="myCommunity">
    <div class="navbar">
      <div class="headBox">
        <div @click="$router.go(-1)">
          <svg-icon iconClass="leftArrow" class="leftArrow"></svg-icon>
        </div>
        <div class="title" v-for="item,index in tabs" :key="item.key" :class="{'isActive': activeTab === index}" @click="tabClick(index)">{{item.title}}</div>
        <div style="width: 25px"></div>
      </div>
    </div>
    <div class="body">
      <v-touch @swipeleft.self="swipeLeft" @swiperight.self="swiperight" :swipe-options="{direction: 'horizontal'}">
        <transition :name="myTabtransition">
          <UnderReview key="underReview" v-if="activeTab == 1" class="bottom-content" />
          <Published key="published" v-if="activeTab == 0" class="bottom-content" />
        </transition>
      </v-touch>
    </div>
  </div>
</template>

<script>
import Published from "@/views/mine/myCommunity/published.vue";
import UnderReview from "@/views/mine/myCommunity/underReview.vue";
import { mapGetters } from "vuex";
export default {
  components: {
    Published,
    UnderReview,
  },
  computed: {
    ...mapGetters({
      userInfo: "userInfo",
    }),
  },
  data() {
    return {
      myTabtransition: "slide-right",
      tabs: [
        {
          title: "已发布",
          key: "published",
        },
        {
          title: "审核中",
          key: "underReview",
        },
      ],
      activeTab: 0,
    };
  },
  created() {
    if (this.$route.query.i) {
      this.activeTab = Number(this.$route.query.i);
    } else {
      this.activeTab = 0;
    }
  },
  methods: {
    // 点击切换
    tabClick(index) {
      if (index > this.activeTab) {
        this.myTabtransition = "slide-left";
      } else {
        this.myTabtransition = "slide-right";
      }
      this.activeTab = index;
      this.$router.replace({
        path: "/myCommunity",
        query: { i: this.activeTab },
      });
    },
    // 监听左滑事件
    swipeLeft() {
      this.activeTab++;
      if (this.activeTab > 1) {
        this.activeTab = 1;
      }
      this.myTabtransition = "slide-left";
      this.$router.replace({
        path: "/myCommunity",
        query: { i: this.activeTab },
      });
    },
    // 监听右滑事件
    swiperight() {
      this.activeTab--;
      if (this.activeTab < 0) {
        this.activeTab = 0;
      }
      this.myTabtransition = "slide-right";
      this.$router.replace({
        path: "/myCommunity",
        query: { i: this.activeTab },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.body {
  height: 100%;
  position: relative;
  overflow-y: auto;
  height: calc(100vh - 50px);
  -webkit-overflow-scrolling: touch;
}
.bottom-content {
  position: absolute;
  width: 100%;
}
.myCommunity {
  .headBox {
    width: 100%;
    height: 44px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: $vermillion;
    padding: 0 16px;
    box-sizing: border-box;
    font-size: 15px;
    color: $white1;

    .title {
      position: relative;
    }

    .isActive {
      font-size: 18px;
      font-weight: 600;
    }

    .isActive::after {
      content: "";
      width: 50%;
      height: 5px;
      background: #fff;
      display: block;
      border-radius: 5px;
      position: absolute;
      bottom: -7px;
      left: 50%;
      transform: translate(-50%, 0);
    }
  }
}
</style>